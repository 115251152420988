import React from 'react';
import { numberToPriceFormat } from '@/helpers/functions';
import { TRevenueCard, TRevenueTotalCard } from '@/types/general';
import { TrendingUp, TrendingDown } from 'lucide-react';

function addPlusOrMinus(value: number) {
  if (value > 0) {
    return (
      <div className="flex text-green-500 gap-1">
        <div>
          + {numberToPriceFormat(value, 1)}
          <span>%</span>
        </div>
        <TrendingUp size={20} />
      </div>
    );
  }

  return (
    <div className="flex text-red-500 gap-1">
      <div>
        - {numberToPriceFormat(value, 1)?.toString().replace('-', '')}
        <span>%</span>
      </div>
      <TrendingDown size={20} />
    </div>
  );
}

export const RevenueTotalCard: React.FC<TRevenueTotalCard> = ({ children, revenuePersentage }): JSX.Element => {
  return (
    <div className={`flex flex-col justify-between bg-white w-72 flex-shrink-0 gap-5 p-4 shadow-md rounded-2xl`}>
      <div>
        <div className="flex flex-row items-center justify-center">
          <div
            className={`text-[14px] text-center w-full ${revenuePersentage ? 'justify-between' : 'justify-center'} px-3 text-[#5f2a6d] font-normal mb-2 flex items-center relative`}
          >
            <span>Chiffre d'affaire</span>
            {revenuePersentage && <span className={`text-sm font-bold`}>{addPlusOrMinus(revenuePersentage)}</span>}
          </div>
        </div>
      </div>
      {children}
    </div>
  );
};

const RevenueCard: React.FC<TRevenueCard> = ({
  name,
  amount,
  count = 0,
  type,
  Icon,
  size,
  aPayeMethod,
  setAPayeMethod,
  oldButtonType,
  buttonType,
  setButtonType,
  setsearchParamsUSedOnce,
  setShowAllImpaye
}): JSX.Element => {
  const handdleClick = () => {
    // make query search used , to reset api behavior
    setsearchParamsUSedOnce({ query: '', used: true });

    // reset show all impayé so the user can not see all transactions again
    setShowAllImpaye(false)

    if (aPayeMethod === name) {
      setAPayeMethod('');
      return;
    }

    if (buttonType === 'logs') {
      setButtonType(oldButtonType);
      setTimeout(() => {
        setAPayeMethod(name);
      }, 100);
      return;
    }

    setAPayeMethod(name);
  };

  return type == 'secondary' ? (
    <button
      onClick={handdleClick}
      className={`flex justify-center items-center flex-col gap-[2rem] min-w-[124px] py-4 px-4 rounded-md ${aPayeMethod == name ? 'bg-[#e6e4e8]' : ''}`}
    >
      {name === 'impayé' ? (
        <div className="relative">
          <Icon size={size} />

          {count > 0 && (
            <span
              className="absolute  bg-[#e6e4e8] text-[orange] rounded-full"
              style={{
                top: '-10px',
                right: '-14px',
                height: '18px',
                width: '18px',
                display: 'grid',
                placeContent: 'center',
                fontSize: '10px',
                fontWeight: 400,
                borderWidth: '1px',
                borderColor: 'orange',
              }}
            >
              {count}
            </span>
          )}
        </div>
      ) : (
        <Icon size={size} />
      )}

      <div
        className="text-lg font-bold whitespace-nowrap text-[#9c97a3]"
        style={{
          fontWeight: 450,
          fontSize: '0.95rem',
          letterSpacing: '0.5px',
        }}
      >
        {numberToPriceFormat(amount)} Dhs
      </div>
    </button>
  ) : (
    <div></div>
  );
};

export default RevenueCard;
