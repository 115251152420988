export type AppointmentSingle = {
  transaction_id: number;
  appointment_id: string;
  appointment_paid_price: number;
  appointment_payment_method: string | JSX.Element;
  appointment_status: string;
  customer_full_name: string;
  appointment_start_date_time: string;
  staff_name: string;
  service_name: string | JSX.Element;
  appointment_sum_price: number | string;
  giftcard_code: string;
  coupon_code: string;
  date_added: string | JSX.Element;
  transaction_group_id?: number | JSX.Element;
  remarque?: string;
  created_by: string;
  is_deleted: boolean;
  username?: string;
  transaction_group_id_int?: number;
  appointment_payment_method_string?: string;
};

type TotalAPaye = {
  _count: number;
  _sum: {
    appointment_sum_price: number | null;
  };
};

type TotalAPayeDate = TotalAPaye | null;

export type AppointmentsData = {
  appointments: AppointmentSingle[];
  totalRevenue: number;
  totalRecords: number;
  totalEspece: number;
  totalCarte: number;
  totalCheque: number;
  totalAPaye: TotalAPaye;
  totalRows: number;
  totalAPayeDate: TotalAPayeDate;
};

type LogEntry = {
  id: number;
  user: {
    username: string;
  };
  action: string;
  os: string;
  adresse_ip: string;
  createdAt: string;
};

export type LogsData = {
  logs: LogEntry[];
  totalLogs: number;
  totalPages: number;
  currentPage: number;
};

export type querType = {
  date_from: string;
  date_to: string;
  page: string;
  pagesize: string;
  appointment_payment_method?: string;
  search?: string;
  filter: string;
  username: string;
};

export type TTotalRevenue = {
  totalEspece: number;
  totalCarte: number;
  totalCheque: number;
  totalRevenue: number;
  totalRevenueBefore: number;
  totalAPaye: {
    count: number;
    sum: number | null;
  };
  totalAPayeFilter: {
    count: number;
    sum: number | null;
  };
};

export type TRegularizeTransaction = {
  transaction_id: number;
  method_payment: string;
};

export type BtnTaype = 'Today' | 'Last 7 Days' | 'Last 30 Days' | 'All' | 'Apply Filters' | 'Range' | 'logs';

export type TRevenueCard = {
  name: string;
  amount: number;
  type: 'primary' | 'secondary';
  count?: number;
  Icon?: ({ size, fill }: IconProps) => JSX.Element;
  size?: number;
  aPayeMethod: string;
  setAPayeMethod: (parm: string) => void;
  oldButtonType: BtnTaype;
  buttonType: string;
  setButtonType: (parm: BtnTaype) => void;
  setsearchParamsUSedOnce: React.Dispatch<React.SetStateAction<{ query: string; used: boolean }>>;
  setShowAllImpaye : ( value : boolean ) => void;
};

export type TRevenueTotalCard = {
  children?: React.ReactNode;
  revenuePersentage: number | undefined;
};

export type RegularizeProps = {
  transaction_id: number;
  price: number | string;
  payment_method: string;
  refetch: () => void;
  remarque?: string;
  created_by?: string;
  giftCardCode: string;
  couponCode: string;
};

export type AvoirProps = {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  transaction_id?: number;
  max_amount?: string | number;
  transaction_group_id?: number;
  refetch?: () => void;
  isAuthorized?: string | undefined;
  isHisOwnTransaction?: boolean;
};

export type TQueries = {
  sortBy?: string;
  sortOrder?: string;
  appointment_payment_method?: string;
  transaction_group_id?: string;
  search?: string;
  transaction_group_id_exclusive?: number;
  showAllImpaye?: boolean;
};

export type IconProps = {
  size?: number;
  fill?: string;
};

export type Tlog = {
  id: string | number;

  user: {
    username: string;
  };
  action: string;
  os: string;
  createdAt: string;
  adresse_ip: Date;
};

export type TotalByMethodPayment = {
  totalAPayeFilter?: {
    count: number;
  };
  totalAPaye: {
    count: number;
  };
};

export enum TransactionLogActions {
  AVOIR = 'AVOIR',
  DECAISSER = 'DECAISSER',
  SUPPRIMER = 'SUPPRIMER',
}
