import { DateRange } from 'react-day-picker';
import { addDays, format } from 'date-fns';
import { TotalByMethodPayment } from '@/types/general';

export function reduceDays(date: Date, days: number) {
  const result = new Date(date);
  result.setDate(result.getDate() - days);
  return result;
}

export function checkAPayeAndTotalAPayeCount(totalByMethodPayment: TotalByMethodPayment) {
  if (totalByMethodPayment?.totalAPayeFilter != null) {
    if (totalByMethodPayment?.totalAPayeFilter.count == 0 && totalByMethodPayment?.totalAPaye.count == 0) {
      return false;
    }
  } else if (totalByMethodPayment?.totalAPaye.count == 0) {
    return false;
  }

  return true;
}

export const numberToPriceFormat = (number: number, fraction = 2) => {
  if (!isNaN(number)) {
    const [integerPart, decimalPart] = number.toFixed(fraction).split('.');
    const newintegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    const formattedNumber = `${newintegerPart},${decimalPart}`;
    return `${formattedNumber}`;
  }
};

export const formatDateTime = (isoString: string) => {
  const options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  };
  return new Date(isoString).toLocaleString('fr-FR', options).replace(',', '');
};

export const getRangeDate = (date: DateRange | undefined, filterBy: string) => {
  if (filterBy === 'Today') {
    return {
      date_from: format(new Date() as Date, 'yyyy-LL-dd'),
      date_to: format(addDays(new Date(), 1) as Date, 'yyyy-LL-dd'),
    };
  }

  if (filterBy === 'Last 7 Days') {
    return {
      date_from: format(reduceDays(new Date(), 7) as Date, 'yyyy-LL-dd'),
      date_to: format(addDays(new Date(), 1), 'yyyy-LL-dd'),
    };
  }

  if (filterBy === 'Last 30 Days') {
    return {
      date_from: format(reduceDays(new Date(), 30) as Date, 'yyyy-LL-dd'),
      date_to: format(addDays(new Date(), 1), 'yyyy-LL-dd'),
    };
  }

  if (filterBy === 'Apply Filters') {
    return {
      date_from: format(date?.from as Date, 'yyyy-LL-dd'),
      date_to: format(date?.to as Date, 'yyyy-LL-dd'),
    };
  }

  if (filterBy === 'Range') {
    if (date && date.to == undefined) {
      return {
        date_from: format(date?.from as Date, 'yyyy-LL-dd'),
        date_to: format(addDays(date?.from as Date, 1), 'yyyy-LL-dd'),
      };
    }

    return {
      date_from: date?.from ? format(date?.from as Date, 'yyyy-LL-dd') : '',
      date_to: date?.to ? format(date?.to as Date, 'yyyy-LL-dd') : '',
    };
  }

  return {
    date_from: '',
    date_to: '',
  };
};
