import { IoIosCloseCircleOutline } from 'react-icons/io';
import { cn } from '@/lib/utils';
import { querType } from '../types/general';
type BtnTaype = 'Today' | 'Last 7 Days' | 'Last 30 Days' | 'All' | 'Apply Filters' | 'Range' | 'logs';

type APayeData = {
  title: string;
  _count: number;
  appointment_sum_price: number | null;
  filters: querType;
  oldButtonType?: BtnTaype;
  setAPayeMethod: (parm: string) => void;
  buttonType: BtnTaype;
  setButtonType: (parm: BtnTaype) => void;
  setSelectedAPaye: (parm: string) => void;
  selectedAPaye: string;
  setShowAllImpaye: React.Dispatch<React.SetStateAction<boolean>>;
  showAllImpaye: boolean;
};

import { numberToPriceFormat } from '@/helpers/functions';

const getColor = (title: string, selectedAPaye: string) => {
  if (title === selectedAPaye) {
    return 'text-[#5f2a6d]';
  }

  if (selectedAPaye === '') {
    return 'text-[orange]';
  }

  return 'text-[#d9d6da]';
};

export default function APaye({
  title,
  _count,
  appointment_sum_price,
  setAPayeMethod,
  buttonType,
  setButtonType,
  oldButtonType = 'All',
  setSelectedAPaye,
  selectedAPaye,
}: APayeData) {
  const handleClick = () => {
    if (selectedAPaye === title) {
      setSelectedAPaye('');
      setAPayeMethod('');
      return;
    }
    setAPayeMethod('impayé');
    setSelectedAPaye(title);

    if (buttonType === 'logs') {
      setButtonType(oldButtonType);
      return;
    }

    if (selectedAPaye === title) {
      setSelectedAPaye('');
      return;
    }

    setButtonType(buttonType);
  };

  return title === 'impayé' ? (
    <button className={`underline  text-sm ${getColor(title, selectedAPaye)}`} onClick={handleClick}>
      {_count} impayé : {numberToPriceFormat(appointment_sum_price)} Dhs
    </button>
  ) : (
    <button className={` text-primary bg-[#fffc7c] text-sm  ${getColor(title, selectedAPaye)}`} onClick={handleClick}>
      {_count} transactions à régulariser - {numberToPriceFormat(appointment_sum_price)} Dhs
    </button>
  );
}

export function TotalAPaye({
  _count,
  appointment_sum_price,
  setAPayeMethod,
  setButtonType,
  setShowAllImpaye,
  showAllImpaye,
}: APayeData) {
  const handleClick = () => {
    setButtonType('All');
    setTimeout(() => {
      setAPayeMethod('impayé');
    }, 0);
  };

  return (
    <button
      className={cn(
        `text-primary bg-[#fffc7c] px-[7px] py-2 rounded-sm shadow-md  text-xs   font-[300]`,
        showAllImpaye && `relative shadow-2xl border-2 border-primary/90`
      )}
      onClick={() => {
        handleClick();
        setShowAllImpaye((prevState) => !prevState);
      }}
    >
      {showAllImpaye && <IoIosCloseCircleOutline size={30} className="absolute -right-6 -top-6" />}
      {_count} Transactions à régulariser : {numberToPriceFormat(appointment_sum_price)} Dhs
    </button>
  );
}
