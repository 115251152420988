import { apiSlice } from './apiSlice';
import { AppointmentsData, querType, TTotalRevenue } from '@/types/general';

export const usersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllTransactions: builder.query<AppointmentsData, querType>({
      query: (filters) => {
        const params = new URLSearchParams(filters).toString();
        return {
          url: `${import.meta.env.VITE_API_URL}/all-transactions?${params}`,
          method: 'GET',
        };
      },
    }),
    getAuth: builder.query<{ status: string; username: string }, {}>({
      query: () => {
        return {
          url: `${import.meta.env.VITE_API_URL}/islogged`,
          method: 'GET',
        };
      },
    }),
    getUserCanDelete: builder.query<{ status: string; username: string }, {}>({
      query: () => {
        return {
          url: `${import.meta.env.VITE_API_URL}/candelete`,
          method: 'GET',
        };
      },
    }),
    getMyUsers: builder.query<{ username: string , full_name : string}[], {}>({
      query: () => {
        return {
          url: `${import.meta.env.VITE_API_URL}/my-users`,
          method: 'GET',
        };
      },
    }),
    getLogs: builder.query<any, any>({
      query: (filters) => {
        const params = new URLSearchParams(filters).toString();
        return {
          url: `${import.meta.env.VITE_API_URL}/logs?${params}`,
          method: 'GET',
        };
      },
    }),
    addToHaveTransaction: builder.mutation<any, any>({
      query: (data) => {
        return {
          url: `${import.meta.env.VITE_API_URL}/to-have`,
          method: 'POST',
          body: data,
        };
      },
      // transformResponse: (response, meta) => {
      //   const rateLimitRemaining = meta.response.headers.get('X-RateLimit-Limit');
      //   // Include rateLimitRemaining in the response data
      //   return { ...response, rateLimitRemaining };
      // },
    }),
    sendVerificationCode: builder.mutation<any, any>({
      query: (data) => {
        return {
          url: `${import.meta.env.VITE_API_URL}/verification-code`,
          method: 'POST',
          body: data,
        };
      },
    }),
    deleteTransaction: builder.mutation<any, any>({
      query: (data) => {
        return {
          url: `${import.meta.env.VITE_API_URL}/delete-transaction`,
          method: 'POST',
          body: data,
        };
      },
    }),
    resetAttempts: builder.mutation<{ message: string }, Record<string, never>>({
      query: (data) => {
        return {
          url: `${import.meta.env.VITE_API_URL}/reset-attempts`,
          method: 'POST',
          body: data,
        };
      },
    }),
    addDisburseTransaction: builder.mutation<any, any>({
      query: (data) => {
        return {
          url: `${import.meta.env.VITE_API_URL}/disburse`,
          method: 'POST',
          body: data,
        };
      },
    }),

    regularizeTransaction: builder.mutation<any, any>({
      query: (data) => {
        return {
          url: `${import.meta.env.VITE_API_URL}/regularize`,
          method: 'POST',
          body: data,
        };
      },
    }),
    checkAvoirLimit: builder.mutation<{ limit: number }, { transactionGroupId: number }>({
      query: (data) => {
        return {
          url: `${import.meta.env.VITE_API_URL}/check-avoir-limit`,
          method: 'POST',
          body: data,
        };
      },
    }),

    getTransactionTotalByMethodPayment: builder.query<TTotalRevenue, querType>({
      query: (filters) => {
        const params = new URLSearchParams(filters).toString();
        return {
          url: `${import.meta.env.VITE_API_URL}/get-transaction-total-by-method-payment?${params}`,
          method: 'GET',
        };
      },
    }),
    getTransactionLogs: builder.query<
      { result: { action_type: string; createdAt: Date; User: { username: string } }[] },
      { transactionID: number }
    >({
      query: (data) => {
        return {
          url: `${import.meta.env.VITE_API_URL}/transaction-log`,
          method: 'POST',
          body: data,
        };
      },
    }),
  }),
});

export const {
  useGetAllTransactionsQuery,
  useDeleteTransactionMutation,
  useGetAuthQuery,
  useGetUserCanDeleteQuery,
  useGetLogsQuery,
  useGetMyUsersQuery,
  useAddToHaveTransactionMutation,
  useAddDisburseTransactionMutation,
  useRegularizeTransactionMutation,
  useGetTransactionTotalByMethodPaymentQuery,
  useCheckAvoirLimitMutation,
  useSendVerificationCodeMutation,
  useGetTransactionLogsQuery,
  useResetAttemptsMutation,
} = usersApiSlice;
