import { useToast } from '@/components/ui/use-toast';
import { Loader2 } from 'lucide-react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import { Button } from './ui/button';
import { useEffect, useState } from 'react';
import {
  useDeleteTransactionMutation,
  useResetAttemptsMutation,
  useSendVerificationCodeMutation,
} from '@/redux/features/usersApiSlice';
import { Input } from './ui/input';
import CountdownTimer from './CountdownTimer';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

type TSupprimer = {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  transaction_id?: number;
  refetch?: () => void;
  isAuthorized?: string | undefined;
};

const Supprimer = ({ isOpen, setIsOpen, transaction_id, refetch, isAuthorized }: TSupprimer) => {
  const { toast } = useToast();
  const [timeOut, setTimeOut] = useState(false);
  const [codeSent, setCodeSent] = useState<boolean>(false);
  const [sendVerificationCode, { isLoading: sendVerifCOdeLoading }] = useSendVerificationCodeMutation();
  const [verificationCode, setverificationCode] = useState<string>('');
  const [attemptsCounter, setattemptsCounter] = useState(3);

  const [deleteTransaction, { isLoading }] = useDeleteTransactionMutation();
  const [resetAttempts] = useResetAttemptsMutation();

  const handleDeleteTransaction = async () => {
    try {
      const result = await deleteTransaction({ transaction_id, verificationCode });

      if (result.error) {
        const statusCode = (result.error as FetchBaseQueryError).status;
        if (statusCode == 419 || statusCode == 429 || statusCode == 401) {
          if (attemptsCounter != 0) {
            setattemptsCounter((prevState) => prevState - 1);
          }
        }
        setverificationCode('');
      } else {
        refetch();
        setIsOpen(false);
        setCodeSent(false);
        setverificationCode('');
        toast({
          title: 'Transaction supprimer avec succès',
        });
      }
    } catch (err) {
      setverificationCode('');
      toast({
        variant: 'destructive',
        title: err.data.message,
      });
    }
  };

  const handleDeleteTransaction_Not_Authorized = async () => {
    try {
      await sendVerificationCode({
        type: 'supprimer',
        data: {
          transactionID: transaction_id,
        },
      }).unwrap();
      setCodeSent(true);
      toast({
        title: 'Code envoyer avec success',
      });
    } catch (error) {
      console.log(error);
      toast({
        variant: 'destructive',
        title: "Erreur lors d'envoyer le code'",
      });
    }
  };

  const loadResetAttempts = async () => {
    await resetAttempts({});
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleDeleteTransaction();
    }
  };

  useEffect(() => {
    setTimeOut(false);
    setattemptsCounter(3);
    loadResetAttempts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(changedValue) => {
        setCodeSent(false);
        setverificationCode('');
        setIsOpen(changedValue);
      }}
    >
      <DialogTrigger asChild></DialogTrigger>

      {isAuthorized == 'authorized' && attemptsCounter != 0 && timeOut == false ? (
        <DialogContent className="px-16 py-14 max-w-xl z-50  bg-[#f9f9fd] ">
          <DialogHeader className="mb-4">
            <DialogTitle className="text-center">Supprimer</DialogTitle>
            <DialogDescription className="text-center  ">Voulez vous supprimer cette transaction</DialogDescription>
          </DialogHeader>

          <DialogFooter>
            <Button onClick={handleDeleteTransaction} disabled={isLoading} className="w-full">
              {isLoading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
              Oui
            </Button>

            <Button
              variant="secondary"
              onClick={() => {
                setCodeSent(false);
                setverificationCode('');
                setIsOpen(false);
              }}
              disabled={isLoading}
              className="w-full"
            >
              Non
            </Button>
          </DialogFooter>
        </DialogContent>
      ) : null}

      {isAuthorized != 'authorized' && codeSent == false && attemptsCounter != 0 && timeOut == false ? (
        <DialogContent className="px-16 py-14 max-w-xl z-50 ">
          <DialogHeader className="mb-4">
            <DialogTitle className="text-center">Supprimer</DialogTitle>
            <DialogDescription className="text-center">Voulez vous supprimer cette transaction</DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button onClick={handleDeleteTransaction_Not_Authorized} disabled={sendVerifCOdeLoading} className="w-full">
              {sendVerifCOdeLoading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
              Envoyer le code de verification
            </Button>
          </DialogFooter>
        </DialogContent>
      ) : null}

      {isAuthorized != 'authorized' && codeSent == true && attemptsCounter != 0 && timeOut == false ? (
        <DialogContent className="px-16 py-14 max-w-xl z-50 ">
          <DialogHeader className="mb-4">
            <DialogTitle className="text-center">Supprimer</DialogTitle>
            <DialogDescription className="text-center">Verifier cette action</DialogDescription>
          </DialogHeader>
          <Input
            className="[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
            placeholder="Code de verification"
            type="number"
            value={verificationCode}
            onChange={(e) => setverificationCode(e.target.value)}
            onKeyDown={handleKeyPress}
          />
          <DialogFooter>
            <div className="w-full">
              <div className="w-full flex justify-between items-center text-gray-500">
                <CountdownTimer initialTime={120} showResendButton={true} setTimeOut={setTimeOut} />
                <Button onClick={handleDeleteTransaction} disabled={isLoading} className="w-full flex-1">
                  {isLoading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
                  Valider
                </Button>
              </div>
              {attemptsCounter > 0 && attemptsCounter < 3 ? (
                <div className="-mb-6 my-6 text-red-400"> {attemptsCounter} Tentatives restantes</div>
              ) : (
                attemptsCounter == 0 && <div className="-mb-6 my-6 text-red-400">Tentatives épuisées</div>
              )}
            </div>
          </DialogFooter>
        </DialogContent>
      ) : null}

      {
        /** if the user has 0 tentaive left */
        attemptsCounter == 0 && (
          <DialogContent className="px-16 py-14 max-w-xl z-50 bg-[#f9f9fd]   ">
            <DialogHeader className="mb-4">
              <DialogTitle className="text-center">Tentatives épuisées veuillez réessayer .</DialogTitle>
            </DialogHeader>
            <div className="-mb-6 my-6 text-red-400">
              <Button
                onClick={() => {
                  setIsOpen(false);
                }}
                variant={'default'}
                className="w-full"
              >
                Fermer
              </Button>
            </div>
          </DialogContent>
        )
      }

      {
        /** if timeout == true */
        timeOut && (
          <DialogContent className="px-16 py-14 max-w-xl z-50 bg-[#f9f9fd]   ">
            <DialogHeader className="mb-4">
              <DialogTitle className="text-center">Temps écoulé veuillez réessayer .</DialogTitle>
            </DialogHeader>
            <div className="-mb-6 my-6 text-red-400">
              <Button
                onClick={() => {
                  setIsOpen(false);
                }}
                variant={'default'}
                className="w-full"
              >
                Fermer
              </Button>
            </div>
          </DialogContent>
        )
      }
    </Dialog>
  );
};

export default Supprimer;
