import { Card } from '@/components/ui/card';
import { RegularizeProps } from '@/types/general';
import { GoInfo } from 'react-icons/go';
import { Badge } from './ui/badge';

export default function Regularize({ payment_method, couponCode, giftCardCode }: RegularizeProps) {
  if (payment_method.includes('Carte Cadeau')) {
    return (
      <span className="capitalize flex justify-center items-center gap-3 text-[12px] ">
        <div> {payment_method}</div>
        <div>
          {(giftCardCode || couponCode) && (
            <div className="group relative overflow-visible  ">
              <GoInfo color="grey" size={18} />
              <Card className="infosquare-content hidden group-hover:block absolute z-50 bottom-0 right-0  p-4">
                <div className="flex flex-col gap-4">
                  {giftCardCode && (
                    <div className="flex w-full justify-start items-center">
                      <div className="w-28 mr-8 ">
                        <Badge variant="default">Code Carte Cadeau</Badge>
                      </div>
                      <div>
                        <p className="text-gray-900   text-xs  mx-4 capitalize "> {giftCardCode}</p>
                      </div>
                    </div>
                  )}

                  {couponCode && (
                    <div className="flex w-full justify-start items-center">
                      <div className="w-28 mr-8 ">
                        <Badge variant="secondary">Coupon Code</Badge>
                      </div>
                      <div>
                        <p className="text-gray-900   text-xs  mx-4 capitalize "> {couponCode}</p>
                      </div>
                    </div>
                  )}
                </div>
              </Card>
            </div>
          )}
        </div>
      </span>
    );
  }

  if (payment_method.includes('remboursement'))
    return (
      <div className="text-[12px]">
        <div className="capitalize"> {payment_method.split(';')[0]} </div>
        <div className="capitalize"> N° Transaction : {payment_method.split(';')[1]} </div>
      </div>
    );

  if (payment_method == 'avoir' || payment_method == 'décaissement') {
    return (
      <div className="flex flex-col justify-center items-center text-[12px] ">
        <span className="capitalize">{payment_method}</span>
      </div>
    );
  }

  if (payment_method == 'impayé') {
    return (
      <div className="flex flex-col items-center gap-1 text-[12px] ">
        <span className="capitalize">{payment_method}</span>
      </div>
    );
  }

  return (
    <span className="capitalize flex justify-center items-center gap-3 text-[12px] ">
      <div> {payment_method}</div>
      {couponCode && (
        <div className="group relative overflow-visible  ">
          <GoInfo color="grey" size={18} />
          <Card className="infosquare-content hidden group-hover:block absolute z-50 bottom-0 right-0  p-4">
            <div className="flex flex-col gap-4">
              {couponCode && (
                <div className="flex w-full justify-start items-center">
                  <div className="w-28 mr-8 ">
                    <Badge variant="default">Coupon Code</Badge>
                  </div>
                  <div>
                    <p className="text-gray-900   text-xs  mx-4 capitalize "> {couponCode}</p>
                  </div>
                </div>
              )}
            </div>
          </Card>
        </div>
      )}
    </span>
  );
}
