import React, { useState, useEffect } from 'react';
// import { Button } from './ui/button';

interface CountdownTimerProps {
  initialTime?: number;
  onTimeEnd?: () => void;
  showResendButton?: boolean;
  setTimeOut: React.Dispatch<React.SetStateAction<boolean>>;
}

const CountdownTimer: React.FC<CountdownTimerProps> = ({
  initialTime = 120,
  onTimeEnd,
  showResendButton = true,
  // onResend,
  // resendLabel = 'Resend',
  setTimeOut,
}) => {
  const [timeLeft, setTimeLeft] = useState<number>(initialTime);
  const [showResend, setShowResend] = useState<boolean>(false);

  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft((prev) => prev - 1);
      }, 1000);

      return () => clearInterval(timer);
    } else {
      setTimeOut(true);
      setShowResend(true);
      if (onTimeEnd) {
        onTimeEnd();
      }
    }
  }, [timeLeft, onTimeEnd]);

  // const handleResend = () => {
  //   setTimeLeft(initialTime);
  //   setShowResend(false);
  //   if (onResend) {
  //     onResend();
  //   }
  // };

  const defaultFormatTime = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
  };

  return (
    <div className=" w-full flex-1">
      {showResend && showResendButton ? (
        <div className="text-sm px-2">Délai épuisé</div>
      ) : (
        <span className="text-nowrap">Temps restant : {defaultFormatTime(timeLeft)}</span>
      )}
    </div>
  );
};

export default CountdownTimer;
