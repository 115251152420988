import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type TUserInfo = {
  jwt: string | null;
};

const initialState: TUserInfo = {
  jwt: localStorage.getItem('jwt') ? localStorage.getItem('jwt') : null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    setJWT: (state, action: PayloadAction<TUserInfo['jwt']>) => {
      state.jwt = action.payload;
      localStorage.setItem('jwt', action.payload ? action.payload : '');
    },
  },
});

export const { setJWT } = authSlice.actions;
export default authSlice.reducer;
