import React from 'react';
import ReactDOM from 'react-dom/client';
import { store } from '@/redux/store';
import App from './App.tsx';
import './index.css';
import { Provider } from 'react-redux';
import { Toaster } from '@/components/ui/toaster';
import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: 'https://186c25efdbd2618ecc9f7e9ceb1aa164@o4507300165713920.ingest.de.sentry.io/4508365436092496',
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
      <Toaster />
    </React.StrictMode>
  </Provider>
);
