import { AppointmentsData, LogsData } from '@/types/general';
import { MRT_ColumnDef } from 'mantine-react-table';

const citiesList = [
  { value: 'Cash', label: 'Cash' },
  { value: 'Carte bancaire', label: 'Carte bancaire' },
  { value: 'Chèque', label: 'Chèque' },
  { value: 'impayé', label: 'impayé' },
  { value: 'avoir', label: 'avoir' },
];

export const columnsArray: MRT_ColumnDef<AppointmentsData['appointments'][number]>[] = [
  {
    accessorKey: 'transaction_id',
    header: 'ID',
    size: 1,
    mantineTableHeadCellProps: {
      align: 'center',
      style: {
        color: 'darkgray',
      },
    },
    mantineTableBodyCellProps: {
      style: {
        color: 'darkgray',
      },
      sx: {
        fontSize: '12px !important',
        textAlign: 'center',
      },
    },
  },
  {
    accessorKey: 'transaction_group_id',
    header: 'Transaction',
    mantineTableHeadCellProps: {
      align: 'center',
    },
  },
  {
    accessorKey: 'date_added',
    header: 'Date de Transaction',
    mantineTableHeadCellProps: {
      align: 'center',
    },
  },
  {
    accessorKey: 'appointment_id',
    header: 'RDV',
    mantineTableHeadCellProps: {
      align: 'center',
    },
  },
  {
    accessorKey: 'appointment_start_date_time',
    header: `Date du RDV`,
    mantineTableHeadCellProps: {
      align: 'center',
    },
  },
  {
    accessorKey: 'service_name',
    header: 'Acte',
    mantineTableHeadCellProps: {
      align: 'center',
    },
    mantineTableBodyCellProps: {
      align: 'center',
      style: {
        fontSize: 12,
      },
      sx: {
        overflow: 'visible',
      },
    },
  },
  {
    accessorKey: 'staff_name',
    header: 'Praticien',
    mantineTableHeadCellProps: {
      align: 'center',
    },
  },
  {
    accessorKey: 'customer_full_name',
    header: 'Patient',
    mantineTableHeadCellProps: {
      align: 'center',
    },
  },

  {
    accessorKey: 'appointment_payment_method',
    header: 'Paiement',
    mantineTableHeadCellProps: {
      align: 'center',
    },
    mantineTableBodyCellProps: {
      align: 'center',
      sx: {
        overflow: 'visible',
      },
    },
    filterVariant: 'multi-select',
    mantineFilterSelectProps: {
      data: citiesList as [],
    },
  },
  {
    accessorKey: 'appointment_sum_price',
    header: 'Montant',
    mantineTableHeadCellProps: {
      align: 'center',
    },
  },
];

export const columnsArrayLogs: MRT_ColumnDef<LogsData>[] = [
  {
    accessorKey: 'username',
    header: 'Utilisateur',
    size: 10,
    mantineTableHeadCellProps: {
      align: 'center',
    },
  },
  {
    accessorKey: 'date_time',
    header: 'Date/Heure',
    mantineTableHeadCellProps: {
      align: 'center',
    },
  },
  {
    accessorKey: 'ip_address',
    header: 'Adresse IP',
    mantineTableHeadCellProps: {
      align: 'center',
    },
  },
  {
    accessorKey: 'os',
    header: 'OS',
    mantineTableHeadCellProps: {
      align: 'center',
    },
  },
  {
    accessorKey: 'actions',
    header: 'Action',
    size: 50,
    mantineTableHeadCellProps: {
      align: 'center',
    },
  },
];
