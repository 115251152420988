export function formatDate_separe(input: string | Date): {
  date: string;
  heure: string;
} {
  try {
    // If input is a string, convert it to a Date object
    const date = typeof input === 'string' ? new Date(input) : input;

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear().toString().slice(2, 4);

    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return {
      date: `${day}/${month}/${year}`,
      heure: `${hours}:${minutes}`,
    };
  } catch (err) {
    return {
      date: '',
      heure: '',
    };
  }
}
