import { useCallback, useEffect, useState } from 'react';

// MantineReactTable

import {
  MRT_SortingState,
  MantineReactTable,
  useMantineReactTable,
  type MRT_PaginationState,
  MRT_ColumnFiltersState,
} from 'mantine-react-table';
import { MRT_Localization_FR } from 'mantine-react-table/locales/fr';
import { columnsArrayLogs } from '../mantine/columns';

import { Badge } from '@/components/ui/badge';
import { useGetLogsQuery } from '@/redux/features/usersApiSlice';
import { Tlog, TQueries } from '@/types/general';

export type querType = {
  page: string;
  pageSize: string;
  search?: string;
  sortBy?: string;
  sortOrder?: string;
  username?: string;
  os?: string;
  ip_address?: string;
  date_time?: string;
  actions?: string;
};

export default function Logs() {
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  // formattedData state
  const [formattedData, setFormattedData] = useState<[]>([]);
  const [filter, setFilter] = useState<querType>({
    page: pagination.pageIndex.toString(),
    pageSize: pagination.pageSize.toString(),
  });

  const [search, setSearch] = useState<string>('');

  const { data: logs, refetch } = useGetLogsQuery(filter);

  const applyFilters = useCallback(
    (filter: querType) => {
      setFilter(filter);
      refetch();
    },
    [setFilter, refetch]
  );

  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);

  useEffect(() => {
    let queries: TQueries = {};

    if (search) {
      queries.search = search;
    }

    if (sorting.length) {
      queries = {
        sortBy: sorting[0].id,
        sortOrder: sorting[0].desc ? 'desc' : 'asc',
      };
    }

    if (columnFilters.length) {
      columnFilters.forEach((filter) => {
        queries = {
          ...queries,
          [filter.id]: filter.value,
        };
      });
    }

    applyFilters({
      page: pagination.pageIndex.toString(),
      pageSize: pagination.pageSize.toString(),
      ...queries,
    });
  }, [pagination.pageIndex, pagination.pageSize, search, sorting, columnFilters, applyFilters]);

  useEffect(() => {
    if (!logs) return;

    const newFormattedData = logs.logs.map((log: Tlog) => ({
      id: log.id,
      username: log.user.username,
      actions:
        log.action === 'Connexion' ? (
          <Badge className="bg-green-500 hover:bg-green-400 pb-[3px] w-full justify-center max-w-24">Connexion</Badge>
        ) : (
          <Badge variant="destructive" className="w-full justify-center max-w-24">
            Déconnexion
          </Badge>
        ),
      os: log.os,
      ip_address: log.adresse_ip,
      date_time: log.createdAt,
    }));

    setFormattedData(newFormattedData);
  }, [logs]);

  const table = useMantineReactTable({
    columns: columnsArrayLogs,
    data: formattedData,
    manualPagination: true,
    manualFiltering: true,
    enableColumnOrdering: false,
    enableColumnActions: false,
    enableColumnDragging: false,
    enableHiding: false,
    enableSorting: true,
    enableColumnPinning: false,
    defaultColumn: {
      minSize: 1,
      maxSize: 3,
      size: 2,
    },
    mantineFilterTextInputProps: {
      size: 'xs',
      placeholder: 'Filtrer',
      styles: () => ({
        input: {
          textAlign: 'center',
        },
        root: {
          width: '50px !important',
          margin: '0 auto',
        },
      }),
    },
    enableFacetedValues: true,
    enableRowActions: false,
    enableGrouping: true,
    initialState: {
      showColumnFilters: true,
      showGlobalFilter: true,
      density: 'xs',
      pagination: {
        pageSize: pagination.pageSize,
        pageIndex: pagination.pageIndex,
      },
    },

    onColumnFiltersChange: setColumnFilters,
    onSortingChange: setSorting,
    onPaginationChange: setPagination,

    state: {
      pagination,
      sorting,
      columnFilters,
    },

    mantineTableBodyCellProps: {
      sx: {
        textAlign: 'center',
        fontSize: '11px !important',
      },
    },
    mantineTableHeadCellProps: {
      sx: {
        fontSize: '11px !important',
        fontWeight: 'bold',
      },
    },
    paginationDisplayMode: 'pages',
    positionToolbarAlertBanner: 'bottom',
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    enableColumnFilterModes: false,
    enableColumnFilters: true,
    mantinePaginationProps: {
      radius: 'xl',
      size: 'md',
      color: 'violet',
    },
    localization: MRT_Localization_FR,
    rowCount: logs?.totalLogs,
    autoResetPageIndex: false,
    onGlobalFilterChange: (value) => {
      setSearch(value);
    },
  });

  return <MantineReactTable table={table} />;
}
