import { Textarea } from '@/components/ui/textarea';
import { useEffect, useState } from 'react';
import {
  useAddToHaveTransactionMutation,
  useCheckAvoirLimitMutation,
  useResetAttemptsMutation,
  useSendVerificationCodeMutation,
} from '@/redux/features/usersApiSlice';
import { Loader2 } from 'lucide-react';
import { Label } from '@/components/ui/label';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { useToast } from '@/components/ui/use-toast';
import { AvoirProps } from '@/types/general';
import CountdownTimer from './CountdownTimer';
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

export default function Avoir({
  isOpen,
  setIsOpen,
  transaction_id,
  refetch,
  transaction_group_id,
  isAuthorized,
  isHisOwnTransaction,
}: AvoirProps) {
  const [remarque, setRemarque] = useState('');
  const [codeSent, setCodeSent] = useState<boolean>(false);
  const [showCaisseOptions, setShowCaisseOptions] = useState<boolean>(false);
  const [showCaisseOptionsError, setShowCaisseOptionsError] = useState<boolean>(false);
  const [saveInOriginalCaisse, setSaveInOriginalCaisse] = useState<string | null>(null);
  const [price, setPrice] = useState('');
  const [verificationCode, setverificationCode] = useState<string>('');
  const { toast } = useToast();
  const [addTransaction, { isLoading }] = useAddToHaveTransactionMutation();
  const [checkAvoirLimit, { isLoading: checkAvoirLimitLoading }] = useCheckAvoirLimitMutation();
  const [sendVerificationCode, { isLoading: sendVerifCOdeLoading }] = useSendVerificationCodeMutation();
  const [resetAttempts] = useResetAttemptsMutation();
  const [attemptsCounter, setattemptsCounter] = useState(3);
  const [timeOut, setTimeOut] = useState(false);

  const sendAvoirData_Authorized = async () => {
    console.log('transaction_group_id:', JSON.stringify(transaction_group_id));
    // Check voir limit
    const { data } = await checkAvoirLimit({ transactionGroupId: transaction_group_id });

    // if the user has nothing left
    if (data.limit == 0) {
      toast({
        title: `Vous avez atteint la limite de création d'un avoir .`,
      });
    }

    // if the user entered an amount greater than the limit
    if (parseFloat(price) > data.limit) {
      toast({
        title: `veuillez saisir un montant inférieur ou égal à ${data.limit}`,
      });
    }

    // if the amount is acceptable and the user is admin
    if (parseFloat(price) <= data.limit) {
      avoirRequest_Authorized();
    }
  };

  const sendAvoirData_NotAuthorized_CodeNotSent = async () => {
    // Check voir limit
    const left = await checkAvoirLimit({ transactionGroupId: transaction_group_id });

    // if the user has nothing left
    if (left.data.limit == 0) {
      toast({
        title: `Vous avez atteint la limite de création d'un avoir .`,
      });
    }

    // if the user entered an amount greater than the limit
    if (parseFloat(price) > left.data.limit) {
      toast({
        title: `veuillez saisir un montant inférieur ou égal à ${left.data.limit}`,
      });
    }

    // if the amount is acceptable and the user is not an admin
    if (parseFloat(price) <= left.data.limit && isAuthorized != 'authorized') {
      // send verification code to database and whatsapp
      try {
        await sendVerificationCode({
          type: 'avoir',
          data: {
            amount: parseFloat(price),
            transactionID: transaction_group_id,
          },
        }).unwrap();
        setCodeSent(true);
        toast({
          title: 'Code envoyer avec success',
        });
      } catch (error) {
        console.log(error);
        toast({
          variant: 'destructive',
          title: "Erreur lors d'envoyer le code'",
        });
      }
    }
  };

  const avoirRequest_Authorized = async () => {
    try {
      const result = await addTransaction({ transaction_id, price, remarque, verificationCode, saveInOriginalCaisse });

      if (result.error) {
        const statusCode = (result.error as FetchBaseQueryError).status;
        if (statusCode == 419 || statusCode == 429 || statusCode == 401) {
          if (attemptsCounter != 0) {
            setattemptsCounter((prevState) => prevState - 1);
          }
        }
        setverificationCode('');
      } else {
        refetch();
        setIsOpen(false);
        setCodeSent(false);
        setPrice('');
        setRemarque('');
        setverificationCode('');
        toast({
          title: 'Transaction créée avec succès',
        });
      }
    } catch (err) {
      setverificationCode('');
      toast({
        variant: 'destructive',
        title: err.data.message,
      });
    }
  };

  useEffect(() => {
    console.log(` saveInOriginalCaisse ==>  ${saveInOriginalCaisse}`);
  }, [saveInOriginalCaisse]);

  const loadResetAttempts = async () => {
    await resetAttempts({});
  };

  useEffect(() => {
    setattemptsCounter(3);
    loadResetAttempts();
    setTimeOut(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      sendAvoirData_Authorized();
    }
  };

  if (!isOpen) return null; // Return null when not open

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(changedValue) => {
        setCodeSent(false);
        setverificationCode('');
        setIsOpen(changedValue);
        setRemarque('');
        setPrice('');
        setShowCaisseOptions(false);
        setSaveInOriginalCaisse(null);
        setShowCaisseOptionsError(false);
      }}
    >
      <DialogTrigger asChild></DialogTrigger>

      {
        /* if the user authorized */
        isAuthorized == 'authorized' && showCaisseOptions == false && attemptsCounter != 0 && timeOut == false ? (
          <DialogContent className="px-16 py-14 max-w-xl z-50 bg-[#f9f9fd]   ">
            <DialogHeader className="mb-4">
              <DialogTitle className="text-center   ">Créer Un Avoir</DialogTitle>
              <DialogDescription className="text-center     ">Ajouter une transaction d'avoir</DialogDescription>
            </DialogHeader>
            <div className="relative">
              <Input
                className="[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                placeholder="Montant"
                type="number"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              />
              <span className="absolute right-6 bottom-2  text-gray-500  select-none">DHS</span>
            </div>
            <Textarea
              className="bg-white"
              placeholder="Écrire votre remarque ici ."
              onChange={(e) => setRemarque(e.target.value)}
              value={remarque}
            />
            <DialogFooter>
              <Button
                onClick={() => {
                  if (isHisOwnTransaction) {
                    sendAvoirData_Authorized();
                  } else {
                    setShowCaisseOptions(true);
                  }
                }}
                variant={'default'}
                className="w-full"
              >
                Valider
              </Button>
            </DialogFooter>
          </DialogContent>
        ) : null
      }

      {
        /* if the user authorized */
        isAuthorized == 'authorized' && showCaisseOptions == true && attemptsCounter != 0 && timeOut == false ? (
          <DialogContent className="px-16 py-14 max-w-xl z-50 bg-[#f9f9fd]   ">
            <DialogHeader className="mb-4">
              <DialogDescription className="text-center text-base text-black">
                Cette transaction appartient à un autre compte, voulez vous créer cet avoir sur votre compte ou celui
                d’origine ?
              </DialogDescription>
            </DialogHeader>
            {showCaisseOptionsError && (
              <div className="text-center mt-0 text-red-500">Veuillez compléter la sélection *</div>
            )}

            <RadioGroup
              onValueChange={setSaveInOriginalCaisse}
              className="flex justify-center gap-6 mb-8 mt-2 text-gray-600"
            >
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="false" id="option-one" />
                <Label htmlFor="option-one">Mon compte</Label>
              </div>
              <div className="flex items-center space-x-2">
                <RadioGroupItem value="true" id="option-two" />
                <Label htmlFor="option-two">Compte d’origine</Label>
              </div>
            </RadioGroup>
            <DialogFooter>
              <Button
                onClick={() => {
                  if (saveInOriginalCaisse == null) {
                    setShowCaisseOptionsError(true);
                  } else {
                    sendAvoirData_Authorized();
                  }
                }}
                disabled={isLoading}
                variant={'default'}
                className="w-full"
              >
                {isLoading || (checkAvoirLimitLoading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />)}
                Valider
              </Button>
            </DialogFooter>
          </DialogContent>
        ) : null
      }

      {
        /* if the user is not authorized and the code is not sent yet */
        isAuthorized != 'authorized' && codeSent == false && attemptsCounter != 0 && timeOut == false ? (
          <DialogContent className="px-16 py-14 max-w-xl z-50 ">
            <DialogHeader className="mb-4">
              <DialogTitle className="text-center">Avoir</DialogTitle>
              <DialogDescription className="text-center">Ajouter une transaction d'avoir</DialogDescription>
            </DialogHeader>
            <div className="relative">
              <Input
                className="[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                placeholder="Montant"
                type="number"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              />
              <span className="absolute right-6 bottom-2  text-gray-500  select-none">DHS</span>
            </div>
            <Textarea
              placeholder="Écrire votre remarque ici ."
              onChange={(e) => setRemarque(e.target.value)}
              value={remarque}
            />
            <DialogFooter>
              <Button
                onClick={sendAvoirData_NotAuthorized_CodeNotSent}
                disabled={sendVerifCOdeLoading}
                className="w-full"
              >
                {sendVerifCOdeLoading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
                Envoyer le code de verification
              </Button>
            </DialogFooter>
          </DialogContent>
        ) : null
      }

      {
        /* if the user is not authorized and the code is sent */
        isAuthorized != 'authorized' && codeSent == true && attemptsCounter != 0 && timeOut == false ? (
          <DialogContent className="px-16 py-14 max-w-xl z-50 ">
            <DialogHeader className="mb-4">
              <DialogTitle className="text-center">Avoir</DialogTitle>
              <DialogDescription className="text-center">Verifier cette action</DialogDescription>
            </DialogHeader>
            <div className="relative">
              <Input
                className="[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                placeholder="Code de verification"
                type="number"
                value={verificationCode}
                onChange={(e) => setverificationCode(e.target.value)}
                onKeyDown={handleKeyPress}
              />
              <span className="absolute right-6 bottom-2  text-gray-500  select-none"></span>
            </div>
            <DialogFooter>
              <div className="w-full">
                <div className="w-full flex justify-between items-center text-gray-500">
                  <CountdownTimer initialTime={120} showResendButton={true} setTimeOut={setTimeOut} />
                  <Button onClick={sendAvoirData_Authorized} disabled={isLoading} className="w-full flex-1">
                    {isLoading || (checkAvoirLimitLoading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />)}
                    Valider
                  </Button>
                </div>
                {attemptsCounter > 0 && attemptsCounter < 3 && (
                  <div className="-mb-6 my-6 text-red-400"> {attemptsCounter} Tentatives restantes</div>
                )}
              </div>
            </DialogFooter>
          </DialogContent>
        ) : null
      }

      {
        /** if the user has 0 tentaive left */
        attemptsCounter == 0 && (
          <DialogContent className="px-16 py-14 max-w-xl z-50 bg-[#f9f9fd]   ">
            <DialogHeader className="mb-4">
              <DialogTitle className="text-center">Tentatives épuisées veuillez réessayer .</DialogTitle>
            </DialogHeader>
            <div className="-mb-6 my-6 text-red-400">
              <Button
                onClick={() => {
                  setIsOpen(false);
                }}
                variant={'default'}
                className="w-full"
              >
                Fermer
              </Button>
            </div>
          </DialogContent>
        )
      }

      {
        /** if timeout == true */
        timeOut && (
          <DialogContent className="px-16 py-14 max-w-xl z-50 bg-[#f9f9fd]   ">
            <DialogHeader className="mb-4">
              <DialogTitle className="text-center">Temps écoulé veuillez réessayer .</DialogTitle>
            </DialogHeader>
            <div className="-mb-6 my-6 text-red-400">
              <Button
                onClick={() => {
                  setIsOpen(false);
                }}
                variant={'default'}
                className="w-full"
              >
                Fermer
              </Button>
            </div>
          </DialogContent>
        )
      }
    </Dialog>
  );
}
