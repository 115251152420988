import { GoInfo } from 'react-icons/go';
import { Card, CardContent } from '@/components/ui/card';
import React from 'react';
import { useGetTransactionLogsQuery } from '@/redux/features/usersApiSlice';
import { TransactionLogActions } from '@/types/general';
import { formatDate_separe } from '@/helpers/date';
import { Badge } from './ui/badge';

type TTransactionInfo = {
  transactionId: number;
  username: string;
  remarque: string;
  payment_method: string;
};

export const TransactionInfo: React.FC<TTransactionInfo> = ({
  transactionId,
  username,
  remarque,
  payment_method,
}): JSX.Element => {
  const { data: transactionLogs } = useGetTransactionLogsQuery({ transactionID: transactionId });

  return (
    <div className="group relative" style={{ textShadow: 'none' }}>
      <GoInfo color="grey" size={18} />
      <Card className="infosquare-content hidden group-hover:block absolute  -bottom-10 right-6 z-50">
        <CardContent className=" flex flex-col  pt-4  text-sm gap-4  ">
          {
            // Rendering transaction owner except for "decaisser"
            payment_method != 'décaissement' && (
              <div className="flex gap-2 justify-start items-center">
                <div className="w-28 ">
                  <Badge variant="default">Encaissé par</Badge>
                </div>
                <div>
                  <p className="text-gray-500  mx-4 capitalize "> {username}</p>
                </div>
              </div>
            )
          }
          {
            // Rendering Transaction logs if available
            transactionLogs?.result?.length > 0 &&
              transactionLogs.result.map((log) => {
                if (log.action_type === TransactionLogActions.SUPPRIMER) {
                  console.log(JSON.stringify(log.createdAt));
                  return (
                    <div className="flex gap-2 justify-start items-center">
                      <div className="w-28 ">
                        <Badge variant="destructive">Supprimé par</Badge>
                      </div>
                      <div>
                        <p className="text-gray-500  mx-4 flex gap-1">
                          <p>{log.User.username}</p>
                          <p>le</p>
                          <p>{formatDate_separe(log.createdAt).date}</p>
                          <p>à</p>
                          <p>{formatDate_separe(log.createdAt).heure}</p>
                        </p>
                      </div>
                    </div>
                  );
                } else if (log.action_type === TransactionLogActions.DECAISSER) {
                  return (
                    <div className="flex gap-2 justify-start items-center">
                      <div className="w-28">
                        <Badge variant="secondary">Décaissé par</Badge>
                      </div>
                      <div>
                        <p className="text-gray-500  mx-4 flex gap-1">
                          <p>{log.User.username}</p>
                          <p>le</p>
                          <p>{formatDate_separe(log.createdAt).date}</p>
                          <p>à</p>
                          <p>{formatDate_separe(log.createdAt).heure}</p>
                        </p>
                      </div>
                    </div>
                  );
                } else {
                  return null;
                }
              })
          }
          {
            // Rendering Remarque
            remarque && (
              <div className="flex gap-2 justify-start items-center ">
                <div className="w-28 ">
                  <Badge variant="outline">Remarque</Badge>
                </div>
                <div>
                  <p className="text-gray-500 mx-4 capitalize">{remarque}</p>
                </div>
              </div>
            )
          }
        </CardContent>
      </Card>
    </div>
  );
};

export default TransactionInfo;
