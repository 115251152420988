import { useEffect, useState } from 'react';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Loader2 } from 'lucide-react';
import {
  useAddDisburseTransactionMutation,
  useResetAttemptsMutation,
  useSendVerificationCodeMutation,
} from '@/redux/features/usersApiSlice';

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';

import { useToast } from '@/components/ui/use-toast';
import { Textarea } from './ui/textarea';
import CountdownTimer from './CountdownTimer';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

export default function Disburse({
  isOpen,
  setIsOpen,
  refetch,
  isAuthorized,
}: {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  refetch?: () => void;
  isAuthorized?: string | undefined;
}) {
  const [remarque, setRemarque] = useState('');
  const [timeOut, setTimeOut] = useState(false);
  const [addTransaction, { isLoading }] = useAddDisburseTransactionMutation();
  const [price, setPrice] = useState('');
  const [attemptsCounter, setattemptsCounter] = useState(3);
  const { toast } = useToast();
  const [codeSent, setCodeSent] = useState<boolean>(false);
  const [sendVerificationCode, { isLoading: sendVerifCOdeLoading }] = useSendVerificationCodeMutation();
  const [verificationCode, setverificationCode] = useState<string>('');
  const [resetAttempts] = useResetAttemptsMutation();
  const handleSave = async () => {
    try {
      const result = await addTransaction({ price, remarque, verificationCode });
      if (result.error) {
        const statusCode = (result.error as FetchBaseQueryError).status;
        if (statusCode == 419 || statusCode == 429 || statusCode == 401) {
          if (attemptsCounter != 0) {
            setattemptsCounter((prevState) => prevState - 1);
          }
        }
        setverificationCode('');
      } else {
        refetch();
        setIsOpen(false);
        setPrice('');
        setRemarque('');
        setCodeSent(false);
        setverificationCode('');
        toast({
          title: 'Décaissement créée avec succès',
        });
      }
    } catch (err) {
      setverificationCode('');
      toast({
        variant: 'destructive',
        title: err.data.message,
      });
    }
  };

  const handleSave_Not_Authorized = async () => {
    try {
      await sendVerificationCode({
        type: 'decaisser',
        data: {
          amount: price,
        },
      }).unwrap();
      setCodeSent(true);
      toast({
        title: 'Code envoyer avec success',
      });
    } catch (error) {
      console.log(error);
      toast({
        variant: 'destructive',
        title: "Erreur lors d'envoyer le code'",
      });
    }
  };

  const loadResetAttempts = async () => {
    await resetAttempts({});
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSave();
    }
  };

  useEffect(() => {
    setattemptsCounter(3);
    loadResetAttempts();
    setTimeOut(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <Dialog
      open={isOpen}
      onOpenChange={(changedValue) => {
        setCodeSent(false);
        setverificationCode('');
        setPrice('');
        setIsOpen(changedValue);
      }}
    >
      <DialogTrigger></DialogTrigger>

      {isAuthorized == 'authorized' && attemptsCounter != 0 && timeOut == false ? (
        <DialogContent className="px-16 py-14 max-w-xl bg-[#f9f9fd] ">
          <DialogHeader className="mb-4">
            <DialogTitle className="text-center">Décaisser</DialogTitle>
            <DialogDescription className="text-center">Veuillez saisir le montant à décaisser</DialogDescription>
          </DialogHeader>
          <div className="relative">
            <Input placeholder="Montant" type="number" value={price} onChange={(e) => setPrice(e.target.value)} />
            <span className="absolute right-6 bottom-2  text-gray-500  select-none">DHS</span>
          </div>
          <Textarea
            className="bg-white"
            placeholder="Écrire votre remarque ici ."
            onChange={(e) => setRemarque(e.target.value)}
            value={remarque}
          />
          <DialogFooter>
            <Button onClick={handleSave} disabled={isLoading} className="w-full">
              {isLoading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
              Valider
            </Button>
          </DialogFooter>
        </DialogContent>
      ) : null}

      {isAuthorized != 'authorized' && codeSent == false && attemptsCounter != 0 && timeOut == false ? (
        <DialogContent className="px-16 py-14 max-w-xl">
          <DialogHeader className="mb-4">
            <DialogTitle className="text-center">Décaisser</DialogTitle>
            <DialogDescription className="text-center">Veuillez saisir le montant à décaisser</DialogDescription>
          </DialogHeader>
          <div className="relative">
            <Input placeholder="Montant" type="number" value={price} onChange={(e) => setPrice(e.target.value)} />
            <span className="absolute right-6 bottom-2  text-gray-500  select-none">DHS</span>
          </div>
          <Textarea
            placeholder="Écrire votre remarque ici ."
            onChange={(e) => setRemarque(e.target.value)}
            value={remarque}
          />
          <DialogFooter>
            <Button onClick={handleSave_Not_Authorized} disabled={sendVerifCOdeLoading} className="w-full">
              {sendVerifCOdeLoading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
              Envoyer le code de verification
            </Button>
          </DialogFooter>
        </DialogContent>
      ) : null}

      {isAuthorized != 'authorized' && codeSent == true && attemptsCounter != 0 && timeOut == false ? (
        <DialogContent className="px-16 py-14 max-w-xl z-50 ">
          <DialogHeader className="mb-4">
            <DialogTitle className="text-center">Décaisser</DialogTitle>
            <DialogDescription className="text-center">Verifier cette action</DialogDescription>
          </DialogHeader>
          <div className="relative">
            <Input
              className="[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
              placeholder="Code de verification"
              type="number"
              value={verificationCode}
              onChange={(e) => setverificationCode(e.target.value)}
              onKeyDown={handleKeyPress}
            />
            <span className="absolute right-6 bottom-2  text-gray-500  select-none">DHS</span>
          </div>
          <DialogFooter>
            <div className="w-full">
              <div className="w-full flex justify-between items-center text-gray-500">
                <CountdownTimer initialTime={120} showResendButton={true} setTimeOut={setTimeOut} />
                <Button onClick={handleSave} disabled={isLoading} className="w-full flex-1">
                  {isLoading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
                  Valider
                </Button>
              </div>
              {attemptsCounter > 0 && attemptsCounter < 3 && (
                <div className="-mb-6 my-6 text-red-400"> {attemptsCounter} Tentatives restantes</div>
              )}
            </div>
          </DialogFooter>
        </DialogContent>
      ) : null}

      {
        /** if the user has 0 tentaive left */
        attemptsCounter == 0 && (
          <DialogContent className="px-16 py-14 max-w-xl z-50 bg-[#f9f9fd]   ">
            <DialogHeader className="mb-4">
              <DialogTitle className="text-center">Tentatives épuisées veuillez réessayer .</DialogTitle>
            </DialogHeader>
            <div className="-mb-6 my-6 text-red-400">
              <Button
                onClick={() => {
                  setIsOpen(false);
                }}
                variant={'default'}
                className="w-full"
              >
                Fermer
              </Button>
            </div>
          </DialogContent>
        )
      }

      {
        /** if timeout == true */
        timeOut && (
          <DialogContent className="px-16 py-14 max-w-xl z-50 bg-[#f9f9fd]   ">
            <DialogHeader className="mb-4">
              <DialogTitle className="text-center">Temps écoulé veuillez réessayer .</DialogTitle>
            </DialogHeader>
            <div className="-mb-6 my-6 text-red-400">
              <Button
                onClick={() => {
                  setIsOpen(false);
                }}
                variant={'default'}
                className="w-full"
              >
                Fermer
              </Button>
            </div>
          </DialogContent>
        )
      }
    </Dialog>
  );
}
