import { configureStore } from '@reduxjs/toolkit';
import { apiSlice } from './features/apiSlice';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import authorization from './features/authorization';

export const store = configureStore({
  reducer: {
    auth: authorization,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiSlice.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const UseAppSelector: TypedUseSelectorHook<RootState> = useSelector;
