// NewRegulariser.tsx

import { useRegularizeTransactionMutation } from '@/redux/features/usersApiSlice';
import { Button } from '@/components/ui/button';
import { Check, Carte, Cash } from '@/components/icons/Icons';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Loader2 } from 'lucide-react';
import { Input } from './ui/input';
import { useState } from 'react';
import { toast } from './ui/use-toast';

type TRegulariser = {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  transaction_id?: number;
  refetch?: () => void;
  price?: number | string;
};

const Regulariser = (props: TRegulariser) => {
  const { isOpen, setIsOpen, transaction_id, refetch, price } = props;

  const [regularizeTransaction, { isLoading }] = useRegularizeTransactionMutation();
  const [paymentMethod, setPaymentMethod] = useState('');
  const [customAmount, setCustomAmount] = useState<number | undefined>(parseFloat(price as string));

  if (!isOpen) return null; // Return null when not open

  const handleRegularize = async () => {
    if (!transaction_id || !refetch) return; // Ensure required props are available

    try {
      if (!paymentMethod.length) {
        return toast({
          variant: 'destructive',
          title: 'Sélectionnez le mode de paiement',
          description: 'Veuillez sélectionner le mode de paiement',
        });
      }

      await regularizeTransaction({
        transaction_id,
        method_payment: paymentMethod,
        customAmount: customAmount ?? 0,
      });
      setIsOpen(false);
      refetch();
    } catch (err) {
      toast({
        variant: 'destructive',
        title: 'Erreur lors de la régularisation',
      });
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent className="px-20 py-10 max-w-4xl gap-y-8">
        <DialogHeader className="mb-4">
          <DialogTitle className="text-center text-2xl mt-2">RÉGULARISER IMPAYÉ</DialogTitle>
        </DialogHeader>

        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
          <div className="relative">
            <Input
              type="text"
              value={customAmount}
              onChange={(e) => setCustomAmount(parseFloat(e.target.value || '0'))}
              className="border w-80 text-center text-3xl font-bold tracking-[1px] p-8 rounded-md"
              autoFocus={true}
            />
            <span className="absolute right-6 top-5 bottom-2 text-lg text-gray-500 select-none">DHS</span>
          </div>
        </div>
        <div className="space-y-4 mx-12 flex flex-row gap-8 items-center -mt-4 justify-evenly">
          {['Cash', 'Carte bancaire', 'Chèque'].map((method) => (
            <button
              key={method}
              className={`flex flex-col justify-center rounded-md border border-input items-center p-4 space-y-4 w-24 h-24 ${
                paymentMethod === method ? 'bg-[#6c70dc]' : 'bg-gray-100'
              }`}
              onClick={() => setPaymentMethod(method)}
            >
              {method === 'Cash' && <Cash fill={paymentMethod === method ? 'white' : '#8f8996'} />}
              {method === 'Carte bancaire' && <Check fill={paymentMethod === method ? 'white' : '#8f8996'} />}
              {method === 'Chèque' && <Carte fill={paymentMethod === method ? 'white' : '#8f8996'} />}
              <span className={`${paymentMethod === method ? 'text-white' : 'text-neutral-500'} text-xs`}>
                {method}
              </span>
            </button>
          ))}
        </div>
        <DialogFooter>
          <div className="w-full flex-row flex justify-between items-center mt-4">
            <Button
              variant="secondary"
              onClick={() => setIsOpen(false)}
              className="w-60 h-12 mx-4 text-base bg-gray-200 text-gray-500 justify-center items-center rounded-sm"
            >
              {isLoading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
              Annuler
            </Button>
            <Button
              onClick={handleRegularize}
              className="w-60 h-12 mx-4 text-base bg-indigo-500 justify-center items-center rounded-sm"
            >
              {isLoading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
              Valider
            </Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default Regulariser;
