import { Card } from '@/components/ui/card';
import { GoInfo } from 'react-icons/go';
import { emptyString } from '@/App';
import { Badge } from './ui/badge';

const ServiceName = ({ service_name }: { service_name: string }) => {
  if (!service_name.includes('Achat Carte Cadeau')) return <>{service_name ? service_name : emptyString}</>;

  const service = service_name.split(' - ')[0];
  const giftCardCode = service_name.split(' - ')[1];

  return (
    <span className="capitalize flex justify-center items-center gap-3 text-[12px] ">
      <div> {service}</div>
      <div>
        {giftCardCode && (
          <div className="group relative overflow-visible  ">
            <GoInfo color="grey" size={18} />
            <Card className="infosquare-content hidden group-hover:block absolute z-50 bottom-0 right-0  p-4">
              <div className="flex flex-col gap-4">
                {giftCardCode && (
                  <div className="flex w-full justify-start items-center">
                    <div className="w-28 mr-8 ">
                      <Badge variant="default">Code Carte Cadeau</Badge>
                    </div>
                    <div>
                      <p className="text-gray-900   text-xs  mx-4 capitalize "> {giftCardCode}</p>
                    </div>
                  </div>
                )}
              </div>
            </Card>
          </div>
        )}
      </div>
    </span>
  );
};

export default ServiceName;
