import { SlNotebook } from 'react-icons/sl';
import { CiGlobe } from 'react-icons/ci';
import { VscAccount } from 'react-icons/vsc';
import { Cash, Check, Carte, APayeIcon } from './components/icons/Icons';
import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { data } from './mantine/data';
import { columnsArray } from './mantine/columns';
import {
  useGetAllTransactionsQuery,
  useGetUserCanDeleteQuery,
  useGetTransactionTotalByMethodPaymentQuery,
  useGetMyUsersQuery,
  useGetAuthQuery,
} from '@/redux/features/usersApiSlice';
import { addDays, format } from 'date-fns';
import { DateRange } from 'react-day-picker';
import { querType, TQueries } from './types/general';
import { AppointmentsData } from '@/types/general';
import { Button } from './components/ui/button';
import { CalendarIcon, Plus } from 'lucide-react';
import { Calendar } from './components/ui/calendar';
import { cn } from './lib/utils';
import { fr } from 'date-fns/locale';
import { useMantineReactTable } from 'mantine-react-table';
import { MRT_Localization_FR } from 'mantine-react-table/locales/fr';
import Regularize from '@/components/Regularize';
import { AppointmentSingle } from '@/types/general';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { formatDateTime } from '@/helpers/functions';
import {
  MantineReactTable,
  type MRT_ColumnDef,
  MRT_SortingState,
  MRT_ColumnFiltersState,
  MRT_PaginationState,
} from 'mantine-react-table';

import { MantineProvider, MantineThemeOverride } from '@mantine/core';
import RevenueCard, { RevenueTotalCard } from './components/RevenueCard';
import Logs from './components/Logs';
import { TotalAPaye } from './components/APaye';
import { getRangeDate, numberToPriceFormat, reduceDays, checkAPayeAndTotalAPayeCount } from './helpers/functions';
import { BtnTaype } from '@/types/general';
import DateAdded from './components/DateAdded';
import SkeletonLoading from './components/Skeleton';
import ServiceName from './components/ServiceName';
import ThemeEditor from 'shadcn-theme-editor';
import TransactionInfo from './components/TransactionInfo';
import { RiEqualizer2Line } from 'react-icons/ri';
import Regulariser from './components/Regulariser';
import Avoir from './components/Avoir';
import Supprimer from './components/Supprimer';
import { MdMoreVert, MdOutlineDeleteOutline } from 'react-icons/md';
import { HiOutlineCash } from 'react-icons/hi';
import Disburse from './components/Disburse';

export const emptyString = '- - -';

const customTheme: MantineThemeOverride = {
  primaryColor: 'violet',
  primaryShade: 5,
  components: {
    Select: {
      styles: () => ({
        input: {
          color: '#708090',
          backgroundColor: 'white',
        },
        label: {
          color: '#708090',
        },
      }),
    },
    Pagination: {
      styles: () => ({
        control: {
          // Styles for unselected buttons
          '&:not([data-active])': {
            backgroundColor: 'white',
            color: '#708090',
            '&:hover': {
              backgroundColor: '#eee',
            },
          },
          // Styles for selected button
          '&[data-active]': {
            backgroundColor: '#7c3aed',
            color: 'white',
            '&:hover': {
              backgroundColor: '#9e6df0',
            },
          },
          '&[data-disabled]': {
            backgroundColor: 'white',
            color: 'grey',
            '&:hover': {
              backgroundColor: 'red',
            },
          },
        },
      }),
    },
  },

  fontSizes: {
    xs: '11px',
  },
};

const App = () => {
  // Regulariser Dialog State
  const [regulariserDialog, setRegulariserDialog] = useState({
    openDialog: false,
    price: null,
    transaction_id: null,
  });
  // Avoir Dialog
  const [avoirDialog, setAvoirDialog] = useState({
    openDialog: false,
    isAuthorized: null,
    isHisOwnTransaction: null,
    transaction_group_id: null,
    transaction_id: null,
    max_amount: null,
  });
  // Supprimer Dialog
  const [supprimerDialog, setSupprimerDialog] = useState({
    openDialog: false,
    isAuthorized: null,
    transaction_id: null,
  });
  // Decaisser Dialog
  const [disburseDialog, setDisburseDialog] = useState({
    openDialog: false,
    isAuthorized: null,
  });

  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 1,
    pageSize: 20,
  });

  const [currentUsername, setCurrentUsername] = useState<string>('');
  const [showAllImpaye, setShowAllImpaye] = useState<boolean>(false);

  const [date, setDate] = useState<DateRange | undefined>({
    from: reduceDays(new Date(), 7),
    to: addDays(new Date(), 1),
  });

  const handleSelectDate = (selectedDate: DateRange | undefined) => {
    if (!selectedDate) {
      setDate(undefined);
      return;
    }

    if (selectedDate.from?.getTime() === selectedDate.to?.getTime()) {
      setDate(undefined);
      return;
    }

    if (selectedDate.from && selectedDate.to) {
      if (date && date.from && date.to) {
        if (date.from !== selectedDate.from && date.to == selectedDate.to) {
          setDate({
            from: selectedDate.from,
            to: undefined,
          });

          return;
        }

        if (date.to !== selectedDate.to && date.from == selectedDate.from) {
          setDate({
            from: selectedDate.to,
            to: undefined,
          });
          return;
        }
      }
    }

    setDate(selectedDate);
  };

  const [buttonType, setButtonType] = useState<BtnTaype>('Last 7 Days');
  const [oldButtonType, setOldButtonType] = useState<BtnTaype>('Last 7 Days');
  const [selectedAPaye, setSelectedAPaye] = useState('');
  const [showLogs, setShowLogs] = useState(false);

  const [sorting, setSorting] = useState<MRT_SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
  const [search, setSearch] = useState<string>('');
  const [aPayeMethod, setAPayeMethod] = useState<string>('');

  const size = 45;

  const [filters, setFilters] = React.useState<querType>({
    date_from: format(date?.from || 0, 'yyyy-LL-dd'),
    date_to: format(date?.to || 0, 'yyyy-LL-dd'),
    page: pagination.pageIndex.toString(),
    pagesize: pagination.pageSize.toString(),
    filter: 'Range',
    username: currentUsername,
  });

  const {
    data: transactions,
    error,
    isLoading,
    refetch,
  } = useGetAllTransactionsQuery(filters, {
    skip: !Object.keys(filters).length,
  });

  const { data: totalByMethodPayment, refetch: refetchMethodPaymentQuery } = useGetTransactionTotalByMethodPaymentQuery(
    filters,
    {
      skip: !Object.keys(filters).length,
    }
  );

  useEffect(() => {
    refetchMethodPaymentQuery();
  }, [buttonType, refetchMethodPaymentQuery]);

  const { data: myUsers } = useGetMyUsersQuery({});

  const { data: userCanDelete } = useGetUserCanDeleteQuery({});

  const { data: getAuthInfo } = useGetAuthQuery({});

  useEffect(() => {
    const username = getAuthInfo?.username;
    if (username != undefined) {
      setCurrentUsername(username);
    }
  }, [getAuthInfo]);

  const applyFilters = useCallback(
    (filter: querType) => {
      setFilters(filter);
      refetch();
      refetchMethodPaymentQuery();
      setShowLogs(false);
    },
    [setFilters, refetch, refetchMethodPaymentQuery, setShowLogs]
  );

  const columns = useMemo<MRT_ColumnDef<AppointmentsData['appointments'][number]>[]>(() => {
    const baseColumn = [...columnsArray];

    baseColumn.push({
      accessorKey: 'Action',
      header: '',
      mantineTableHeadCellProps: {
        align: 'center',
      },
      mantineTableBodyCellProps: {
        sx: {
          overflow: 'visible',
        },
      },
      enableColumnFilter: false,
      enableSorting: false,
      Cell: ({ row }) => (
        <div className="flex gap-2 justify-start items-center">
          <Popover>
            <PopoverTrigger asChild>
              <Button variant="ghost" className="w-fit h-fit p-0 m-0">
                <MdMoreVert color="grey" size={19} />
              </Button>
            </PopoverTrigger>
            <PopoverContent className="flex flex-col gap-2 py-2 w-40 ">
              {
                // if this transaction payment method == 'impayé' , then show regulariser component
                row.original.appointment_payment_method_string == 'impayé' && (
                  <Button
                    onClick={() => {
                      setRegulariserDialog({
                        openDialog: true,
                        price: parseFloat(row.original.appointment_sum_price as string),
                        transaction_id: row.original.transaction_id,
                      });
                    }}
                    variant={'ghost'}
                    className="flex justify-start items-center gap-2 w-full"
                  >
                    <RiEqualizer2Line className="text-black" size={20} />
                    <span className="text-xs">Régulariser</span>
                  </Button>
                )
              }

              {
                // no avoir button for deleted item or decaissement
                !row.original.is_deleted && row.original.appointment_payment_method_string != 'décaissement' && (
                  <Button
                    onClick={() => {
                      setAvoirDialog({
                        openDialog: true,
                        isAuthorized: userCanDelete?.status,
                        isHisOwnTransaction: userCanDelete?.username == row.original.username,
                        transaction_group_id: row.original.transaction_group_id_int,
                        transaction_id: row.original.transaction_id,
                        max_amount: row.original.appointment_sum_price,
                      });
                    }}
                    variant={'ghost'}
                    className="flex justify-start items-center gap-2 w-full"
                  >
                    <Plus className="text-black" size={20} />
                    <span className="text-xs">Avoir</span>
                  </Button>
                )
              }
              {
                // if the line is not deleted yet show supprimer button
                !row.original.is_deleted && (
                  <Button
                    onClick={() => {
                      setSupprimerDialog({
                        openDialog: true,
                        isAuthorized: userCanDelete?.status,
                        transaction_id: row.original.transaction_id,
                      });
                    }}
                    variant={'ghost'}
                    className="flex justify-start items-center gap-2 w-full"
                  >
                    <MdOutlineDeleteOutline size={18} className="text-black" />
                    <span className="text-xs">Supprimer</span>
                  </Button>
                )
              }
              {/* show disburse for all transactions */}
              <Button
                onClick={() => {
                  setDisburseDialog({
                    openDialog: true,
                    isAuthorized: userCanDelete?.status,
                  });
                }}
                variant={'ghost'}
                className="flex justify-start items-center gap-2 w-full"
              >
                <HiOutlineCash size={18} className="text-black" />
                <span className="text-xs">Décaisser</span>
              </Button>
            </PopoverContent>
          </Popover>

          <TransactionInfo
            payment_method={row.original.appointment_payment_method_string}
            remarque={row.original.remarque}
            transactionId={row.original.transaction_id}
            username={row.original.username}
          />
        </div>
      ),
    });

    return baseColumn;
  }, [userCanDelete?.status, userCanDelete?.username]);

  useEffect(() => {
    const py = columnFilters.find((filter) => filter.id === 'appointment_payment_method')?.value || '';
    setAPayeMethod(py as string);
  }, [columnFilters]);

  const [searchParamsUSedOnce, setsearchParamsUSedOnce] = useState<{ query: string; used: boolean } | null>(null);

  useEffect(() => {
    if (searchParamsUSedOnce == null && window.location.search.length > 0) {
      const params = new URLSearchParams(window.location.search);
      const groupId = params.get('transaction_group_id_exclusive');
      setsearchParamsUSedOnce({ query: groupId as string, used: false });
    }

    if (buttonType == 'logs') {
      setShowLogs(true);
      return;
    }

    let queries: TQueries = {};

    if (sorting.length) {
      queries = {
        sortBy: sorting[0].id,
        sortOrder: sorting[0].desc ? 'desc' : 'asc',
      };
    } else {
      queries = {
        sortBy: 'date_added',
        sortOrder: 'desc',
      };
    }

    if (searchParamsUSedOnce && searchParamsUSedOnce?.used == false) {
      queries.transaction_group_id_exclusive = parseInt(searchParamsUSedOnce.query);
      filter('All');
    }

    if (showAllImpaye) {
      queries.showAllImpaye = true;
    }

    if (aPayeMethod.length) {
      queries.appointment_payment_method = aPayeMethod;
    }

    if (columnFilters.length) {
      columnFilters.forEach((filter) => {
        queries = {
          ...queries,
          [filter.id]: filter.value,
        };
      });
    }

    if (search) {
      queries.search = search;
    }

    applyFilters({
      ...getRangeDate(date, buttonType),
      page: pagination.pageIndex.toString(),
      pagesize: pagination.pageSize.toString(),
      filter: buttonType,
      username: currentUsername,
      ...queries,
    });
  }, [
    sorting,
    search,
    pagination,
    buttonType,
    date,
    aPayeMethod,
    columnFilters,
    currentUsername,
    applyFilters,
    searchParamsUSedOnce,
    showAllImpaye,
  ]);

  const formattedData = useMemo(() => {
    if (transactions) {
      return transactions.appointments.map((item: AppointmentSingle) => {
        return {
          ...item,
          appointment_payment_method: (
            <Regularize
              transaction_id={item.transaction_id}
              payment_method={item.appointment_payment_method as string}
              refetch={refetch}
              price={item.appointment_sum_price}
              remarque={item.remarque}
              created_by={item.created_by}
              giftCardCode={item.giftcard_code}
              couponCode={item.coupon_code}
            />
          ),
          appointment_start_date_time: item.appointment_start_date_time
            ? formatDateTime(item.appointment_start_date_time)
            : emptyString,
          appointment_status: item.appointment_status ? item.appointment_status : emptyString,
          giftcard_code: item.giftcard_code ? item.giftcard_code : emptyString,
          customer_full_name: item.customer_full_name ? item.customer_full_name : emptyString,
          coupon_code: item.coupon_code ? item.coupon_code : emptyString,
          appointment_id: item.appointment_id ? item.appointment_id : emptyString,
          service_name: <ServiceName service_name={item.service_name as string} />,
          staff_name: item.staff_name ? item.staff_name : emptyString,
          date_added: <DateAdded date_added={formatDateTime(item.date_added as string)} />,
          appointment_sum_price: `${item.appointment_sum_price} Dhs`,
          transaction_group_id: item.transaction_group_id ? (
            <div className="capitalize underline"> #{item.transaction_group_id.toString().padStart(4, '0')} </div>
          ) : (
            <div>{emptyString}</div>
          ),
          transaction_group_id_int: item.transaction_group_id as number,
          appointment_payment_method_string: item.appointment_payment_method as string,
        };
      });
    } else {
      return [];
    }
  }, [transactions, refetch]);

  const table = useMantineReactTable({
    columns,
    data: formattedData,
    enableColumnOrdering: false,
    enableColumnActions: false,
    enableColumnDragging: false,
    enableHiding: false,
    enableSorting: true,
    manualSorting: true,
    state: {
      sorting,
      columnFilters,
      pagination,
    },
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onPaginationChange: setPagination,
    rowCount: transactions?.totalRows || 0,
    autoResetPageIndex: false,
    enableColumnPinning: false,
    defaultColumn: {
      minSize: 1,
      maxSize: 3,
      size: 2,
    },
    mantinePaperProps: {
      style: {
        borderRadius: 14,
        overflow: 'visible',
        clipPath: 'none',
      },
    },
    mantineTableContainerProps: {
      style: {
        borderRadius: 14,
        overflow: 'visible',
        clipPath: 'none',
      },
    },
    enableFacetedValues: true,
    enableRowActions: false,
    positionActionsColumn: 'last',
    enableGrouping: true,
    initialState: { showColumnFilters: false, showGlobalFilter: true, density: 'xs' },
    mantineTableBodyCellProps: {
      sx: {
        textAlign: 'center',
        fontSize: '12px !important',
      },
    },
    mantineTableHeadCellProps: {
      sx: {
        fontSize: '12px !important',
        fontWeight: 'bold',
        padding: '3px 2px !important',
      },
    },

    mantineTableBodyRowProps: (row) => {
      const { payment_method } = (row.row.original.appointment_payment_method as JSX.Element).props;
      const { is_deleted } = row.row.original;

      return {
        sx: {
          backgroundColor: payment_method == 'impayé' ? '#fffc7c !important' : 'white',

          textShadow: is_deleted && '0 0 5px rgba(0, 0, 0, 0.5)',
          color: is_deleted ? '#d1d1d1' : 'black',
          '&:hover': {
            backgroundColor: payment_method == 'impayé' ? '#fffc7c !important' : '#f9f9fd',
            color: 'black',
          },
          '&:hover td': {
            backgroundColor: payment_method == 'impayé' ? '#fffc7c !important' : '#f9f9fd',
            color: 'black',
          },
        },
      };
    },

    paginationDisplayMode: 'pages',
    positionToolbarAlertBanner: 'bottom',
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    enableColumnFilterModes: false,
    mantineTableHeadRowProps: {
      height: 34,
      style: {
        overflow: 'visible',
      },
    },
    mantineFilterTextInputProps: {
      size: 'xs',
      placeholder: 'Filtrer',
      styles: () => ({
        input: {
          textAlign: 'center',
        },
        root: {
          width: '50px !important',
          margin: '0 auto',
        },
      }),
    },
    mantineFilterSelectProps: {
      size: 'xs',
      styles: () => ({
        input: {
          textAlign: 'center',
        },
        root: {
          width: '50px !important',
          margin: '0 auto',
        },
      }),
    },
    mantineFilterMultiSelectProps: {
      size: 'xs',
      styles: () => ({
        input: {
          textAlign: 'center',
        },
        root: {
          width: '50px !important',
          margin: '0 auto',
        },
      }),
    },
    enableColumnFilters: true,
    mantineTableProps: {
      striped: true,
    },
    mantinePaginationProps: {
      radius: 'xl',
      size: 'md',
    },
    localization: MRT_Localization_FR,
    manualFiltering: true,
    manualPagination: true,
    onGlobalFilterChange: (value) => {
      setSearch(value);
    },
  });

  const filter = (value: BtnTaype) => {
    setButtonType(value);
    setAPayeMethod('');
  };

  useEffect(() => {}, [columnFilters]);

  // handler to refetch on visibility change
  const handleVisibilityChange = useCallback(() => {
    if (document.visibilityState === 'visible') {
      console.log('refetch on visibility change');
      refetch();
    }
  }, [refetch]);

  // handler to refetch on window resizing
  const handleResize = useCallback(() => {
    console.log('refetch on window resizing');
    refetch();
  }, [refetch]);

  // adding handlers to windows events
  useEffect(() => {
    window.addEventListener('visibilitychange', handleVisibilityChange);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('resize', handleResize);
    };
  }, [handleVisibilityChange, handleResize]);

  return isLoading ? (
    <SkeletonLoading />
  ) : error ? (
    <div className="w-full  h-auto flex justify-center items-center">Vous n'êtes pas authentifié</div>
  ) : (
    <>
      <div className="w-full px-10 h-auto pb-20 bg-[#f9f9fd]">
        <div className="mb-10">
          <div className="flex  justify-start items-start  w-full fixed bg-[#f9f9fd] top-0 left-0 right-0 z-50 pl-7 h-[80px] ">
            <span className="text-[1.875rem] whitespace-nowrap font-normal text-[#1e293b] ml-3 opacity-50 pb-[15px] fixed top-[-6px] ">
              La Caisse
            </span>

            <div className="flex justify-center items-center gap-[4.5rem]  ml-[21rem] text-[17px] ">
              {userCanDelete?.status && (
                <div
                  className={cn(
                    'flex justify-center items-center cursor-pointer p-[0.25rem] mb-[0.75rem] mr-[0.75rem]',
                    currentUsername == ''
                      ? ' opacity-50 text-[#1e293b]  pb-[5px] border-1 border-b-2 border-[#1e293b] '
                      : ' opacity-50 text-[#1e293b]'
                  )}
                  onClick={() => {
                    setCurrentUsername('');
                    filter('Last 7 Days');
                    setsearchParamsUSedOnce({ query: '', used: true });
                  }}
                >
                  <CiGlobe size={20} className="mr-[0.8rem]" />
                  <div className="capitalize"> Tout </div>
                </div>
              )}
              {userCanDelete?.status &&
                myUsers.map((user) => {
                  const baseStyle =
                    'flex justify-center items-center cursor-pointer px-[0.45rem] mb-[0.75rem] mr-[0.75rem]';
                  const style =
                    currentUsername == user.username
                      ? ' opacity-50 text-[#1e293b]   pb-[5px] border-1 border-b-2 border-[#1e293b] '
                      : ' opacity-50 text-[#1e293b] ';
                  return (
                    <div
                      key={user.username}
                      className={cn(baseStyle, style, '')}
                      onClick={() => {
                        setCurrentUsername(user.username);
                        filter('Last 7 Days');
                        setsearchParamsUSedOnce({ query: '', used: true });
                      }}
                    >
                      <VscAccount
                        size={20}
                        className="mr-[0.8rem]"
                        color={currentUsername == user.username ? '#1e293b' : '#1e293b'}
                      />
                      <div className="capitalize whitespace-nowrap "> {user.full_name} </div>
                    </div>
                  );
                })}
            </div>

            <div className="ml-auto w-full flex justify-evenly">
              {userCanDelete?.status && (
                <>
                  {/* <div className="w-[2px] mt-1 h-6 bg-gray-500  text-[#1e293b] opacity-40"></div> */}
                  <div
                    className={cn(
                      'flex justify-center items-center cursor-pointer p-[0.25rem] mb-[0.75rem]',
                      buttonType == 'logs'
                        ? ' text-[1.125rem] text-[#2c2c2c] pb-[5px] border-1 border-b-2 border-[#2c2c2c] '
                        : ' opacity-60 text-[1rem] text-[#1e293b]'
                    )}
                    onClick={() => {
                      setOldButtonType(buttonType);
                      setAPayeMethod('');
                      setButtonType('logs');
                    }}
                  >
                    {/*
                    
                    ACTIONS LOG : COMMENTED

                    <SlNotebook className="mr-[0.8rem]" size={17} style={{ stroke: '#1e293b', strokeWidth: 25 }} />
                    <div className="text-nowrap">Actions Log</div>
                    
                    */}
                  </div>
                </>
              )}
            </div>
          </div>

          <div className=" flex justify-between  items-center w-full   pt-[110px]">
            {data && (
              <div className="flex h-56 my-10 gap-10 w-full ">
                <RevenueTotalCard revenuePersentage={totalByMethodPayment?.totalRevenueBefore}>
                  <div className="">
                    <div
                      className="text-xl  text-center tracking-[1px] text-[#5f2a6d]"
                      style={{ fontSize: '1.75rem', lineHeight: '1.75rem' }}
                    >
                      {numberToPriceFormat(totalByMethodPayment?.totalRevenue)}
                      <sup
                        style={{
                          top: '-0.80em',
                          fontWeight: '600',
                          fontSize: '45%',
                        }}
                      >
                        Dhs
                      </sup>
                    </div>
                  </div>

                  {checkAPayeAndTotalAPayeCount(totalByMethodPayment) ? (
                    <div className="flex gap-4 py-2 flex-col items-center">
                      <TotalAPaye
                        title="Total impayé"
                        _count={totalByMethodPayment?.totalAPaye.count as number}
                        appointment_sum_price={totalByMethodPayment?.totalAPaye.sum as number}
                        setAPayeMethod={setAPayeMethod}
                        filters={filters}
                        buttonType={'All'}
                        setButtonType={setButtonType}
                        setSelectedAPaye={setSelectedAPaye}
                        selectedAPaye={selectedAPaye}
                        setShowAllImpaye={setShowAllImpaye}
                        showAllImpaye={showAllImpaye}
                      />
                    </div>
                  ) : (
                    <div className="h-7"></div>
                  )}
                </RevenueTotalCard>

                <div className="flex flex-col   w-full  h-full justify-between">
                  <div className="flex justify-between w-full flex-nowrap gap-2 flex-row  ">
                    <Button
                      variant={buttonType == 'Today' ? 'default' : 'outline'}
                      onClick={() => {
                        filter('Today');
                        setsearchParamsUSedOnce({ query: '', used: true });
                      }}
                      className={cn('font-[350]  w-[150px]', buttonType != 'Today' && ' text-[#818181] text-xs')}
                    >
                      Aujourd'hui
                    </Button>

                    <Button
                      variant={buttonType == 'Last 7 Days' ? 'default' : 'outline'}
                      onClick={() => {
                        filter('Last 7 Days');
                        setsearchParamsUSedOnce({ query: '', used: true });
                      }}
                      className={cn('font-[350]  w-[150px]', buttonType != 'Last 7 Days' && 'text-[#818181] text-xs')}
                    >
                      Les 7 derniers jours
                    </Button>

                    <Button
                      variant={buttonType == 'Last 30 Days' ? 'default' : 'outline'}
                      onClick={() => {
                        filter('Last 30 Days');
                        setsearchParamsUSedOnce({ query: '', used: true });
                      }}
                      className={cn('font-[350]  w-[150px]', buttonType != 'Last 30 Days' && 'text-[#818181] text-xs')}
                    >
                      Les 30 derniers jours
                    </Button>

                    <Button
                      variant={buttonType == 'All' ? 'default' : 'outline'}
                      onClick={() => {
                        filter('All');
                        setsearchParamsUSedOnce({ query: '', used: true });
                      }}
                      className={cn('font-[350] w-22 ', buttonType != 'All' && 'text-[#818181] text-xs')}
                    >
                      Tout
                    </Button>

                    <Popover>
                      <PopoverTrigger asChild>
                        <Button
                          id="date"
                          variant={buttonType == 'Range' ? 'default' : 'outline'}
                          onClick={() => filter('Range')}
                          className={cn(
                            'w-fit justify-start text-left font-[350] text-xs',
                            !date && 'text-muted-foreground',
                            buttonType != 'Range' && 'text-[#818181]'
                          )}
                        >
                          <CalendarIcon className="mr-2 h-4 w-4" />

                          {date?.from ? (
                            date.to ? (
                              <>
                                {format(date.from, 'dd LLL y', { locale: fr })} -{' '}
                                {format(date.to, 'dd LLL y', { locale: fr })}
                              </>
                            ) : (
                              format(date.from, 'dd LLL y', { locale: fr })
                            )
                          ) : (
                            <span>Choisissez une date</span>
                          )}
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent className="w-auto p-0" align="start">
                        <Calendar
                          initialFocus
                          mode="range"
                          defaultMonth={date?.from}
                          selected={date}
                          onSelect={handleSelectDate}
                          numberOfMonths={2}
                        />
                      </PopoverContent>
                    </Popover>
                    <Button
                      variant={buttonType == 'Apply Filters' ? 'default' : 'outline'}
                      onClick={() => {
                        filter('Apply Filters');
                        setsearchParamsUSedOnce({ query: '', used: true });
                      }}
                      className={cn('font-[350]  w-[150px]', buttonType != 'Apply Filters' && 'text-[#818181] text-xs')}
                    >
                      Appliquer filtre
                    </Button>
                  </div>

                  <div className="flex w-full justify-between items-center gap-[26px]">
                    <div
                      className="flex w-full justify-between"
                      style={{
                        marginBottom: '25px',
                        marginTop: '0px',
                      }}
                    >
                      <RevenueCard
                        amount={totalByMethodPayment?.totalEspece ?? 0}
                        name="Cash"
                        type="secondary"
                        Icon={Cash}
                        size={42}
                        aPayeMethod={aPayeMethod}
                        setAPayeMethod={setAPayeMethod}
                        oldButtonType={oldButtonType}
                        buttonType={buttonType}
                        setButtonType={setButtonType}
                        setsearchParamsUSedOnce={setsearchParamsUSedOnce}
                        setShowAllImpaye={setShowAllImpaye}
                      />

                      <RevenueCard
                        amount={totalByMethodPayment?.totalCarte ?? 0}
                        name="Carte bancaire"
                        type="secondary"
                        Icon={Carte}
                        size={size}
                        aPayeMethod={aPayeMethod}
                        setAPayeMethod={setAPayeMethod}
                        oldButtonType={oldButtonType}
                        buttonType={buttonType}
                        setButtonType={setButtonType}
                        setsearchParamsUSedOnce={setsearchParamsUSedOnce}
                        setShowAllImpaye={setShowAllImpaye}
                      />

                      <RevenueCard
                        amount={totalByMethodPayment?.totalCheque ?? 0}
                        name="Chèque"
                        type="secondary"
                        Icon={Check}
                        size={42}
                        aPayeMethod={aPayeMethod}
                        setAPayeMethod={setAPayeMethod}
                        oldButtonType={oldButtonType}
                        buttonType={buttonType}
                        setButtonType={setButtonType}
                        setsearchParamsUSedOnce={setsearchParamsUSedOnce}
                        setShowAllImpaye={setShowAllImpaye}
                      />

                      <RevenueCard
                        amount={(totalByMethodPayment?.totalAPayeFilter?.sum as number) ?? 0}
                        name="impayé"
                        type="secondary"
                        Icon={APayeIcon}
                        size={40}
                        aPayeMethod={aPayeMethod}
                        setAPayeMethod={setAPayeMethod}
                        oldButtonType={oldButtonType}
                        buttonType={buttonType}
                        setButtonType={setButtonType}
                        count={totalByMethodPayment?.totalAPayeFilter?.count as number}
                        setsearchParamsUSedOnce={setsearchParamsUSedOnce}
                        setShowAllImpaye={setShowAllImpaye}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <MantineProvider theme={customTheme}>
          {showLogs ? <Logs /> : <MantineReactTable table={table} />}
        </MantineProvider>
        {/*
        Show it only in dev mode

        <ThemeEditor />
        
        */}
        {
          /* Calling Regulariser Dialog */
          regulariserDialog.openDialog && (
            <Regulariser
              isOpen={regulariserDialog.openDialog}
              setIsOpen={(isOpen: boolean) =>
                setRegulariserDialog((prevState) => ({ ...prevState, openDialog: isOpen }))
              }
              price={regulariserDialog.price ?? undefined}
              refetch={refetch}
              transaction_id={regulariserDialog.transaction_id ?? undefined}
            />
          )
        }
        {
          /* Calling Avoir Dialog */
          avoirDialog.openDialog && (
            <Avoir
              isOpen={avoirDialog.openDialog}
              setIsOpen={(isOpen: boolean) => setAvoirDialog((prevState) => ({ ...prevState, openDialog: isOpen }))}
              isAuthorized={avoirDialog.isAuthorized}
              isHisOwnTransaction={avoirDialog.isHisOwnTransaction}
              refetch={refetch}
              transaction_group_id={avoirDialog.transaction_group_id}
              transaction_id={avoirDialog.transaction_id}
              max_amount={avoirDialog.max_amount}
            />
          )
        }
        {
          /* Calling Supprimer Dialog */
          supprimerDialog.openDialog && (
            <Supprimer
              isOpen={supprimerDialog.openDialog}
              setIsOpen={(isOpen: boolean) => setSupprimerDialog((prevState) => ({ ...prevState, openDialog: isOpen }))}
              isAuthorized={supprimerDialog.isAuthorized}
              refetch={refetch}
              transaction_id={supprimerDialog.transaction_id}
            />
          )
        }

        {
          /* Calling Disburse Dialog */
          disburseDialog.openDialog && (
            <Disburse
              isOpen={disburseDialog.openDialog}
              setIsOpen={(isOpen: boolean) => setDisburseDialog((prevState) => ({ ...prevState, openDialog: isOpen }))}
              isAuthorized={disburseDialog.isAuthorized}
              refetch={refetch}
            />
          )
        }
      </div>
    </>
  );
};

export default App;
